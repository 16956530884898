import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { isValidFundId } from '@gorila-bot/gorila-front-utils';
import { Fund, UserDataSelectors, UserDataState } from '@gorila-bot/user-data-store';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { DashboardRoutePath } from '../app-shell/dashboard/metadata';
import { ManagerRoutePath } from '../app-shell/manager/metadata';

@Injectable()
export class AdvisorFundGuard implements CanActivate {
  public constructor(
    protected router: Router,
    protected store: Store<UserDataState.State>,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([
      this.store.select(UserDataSelectors.selectFeatureCurrentFundData),
      this.store.select(UserDataSelectors.selectFeatureCurrentLoggedFundData)
    ]).pipe(
      map(([curFundData, loggedFunddata]: [Fund, Fund]) => {
        if (!isValidFundId(loggedFunddata.fundId)) {
          return true;
        }

        if (curFundData.fundId !== loggedFunddata.fundId) {
          return true;
        }
        const path = route.routeConfig.path;
        if ([ManagerRoutePath].indexOf(path) !== -1) {
          this.router.navigate(['app', DashboardRoutePath]);
          return false;
        }

        return true;
      })
    );
  }
}
