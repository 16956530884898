// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { PremiumPlanTier } from '@gorila-bot/premium';
import { ClientType } from '@gorila-bot/gorila-front-models';

import { Env } from './models/env.model';

export const environmentBase: Env = {
  production: false,
  name: 'localhost',
  firebase: {
    certPush: 'BPWFDgkxF_fggDQGuG8B3W5ADLYUVkcVSUS0MfySdmX9M7-RBWG3bpyn_D2onYiIbC6bGkNviCRhOPuJ8hPQTU0',
    config: {
      apiKey: 'AIzaSyChduF127rbQNyLGfvE__seHor7EDJJfQU',
      authDomain: 'gorila-sandbox.firebaseapp.com',
      projectId: 'gorila-sandbox',
      storageBucket: 'gorila-sandbox.appspot.com',
      messagingSenderId: '559875557841',
      appId: '1:559875557841:web:a98f7c933bdebb341cb8f0',
      measurementId: 'G-G83BL5GYPH',
    }
  },
  useAccessToken: false,
  enableMockedData: false,
  disableAnimations: false,
  Auth0: {
    allowSignUp: true,
    clientID: 'AsogTpZExRxMKSE2cLQrYu18u9WUVqNJ',
    domain: 'gorilainvest.auth0.com',
    responseType: 'token id_token',
    audience: 'https://gorilainvest.auth0.com/api/v2/',
    redirectUrl: location.protocol + '//' + location.host + '/',
    scope: 'openid email app_metadata user_metadata', // Learn about scopes: https://auth0.com/docs/scopes
    database: 'Username-Password-Authentication'
  },
  Vindi: {
    b2b: {
      clientID: 'F9RChJ2_d4nCuO-AOCEsVZvlJNxgwO1loJ2jovKPOjE',
      apiUrl: 'https://sandbox-app.vindi.com.br/api/v1/public/payment_profiles'
    },
    b2c: {
      clientID: 'ejZMVUkH-K4rY7QD0dKbQ3MeY_KMXooUDQ8CTPfcabY',
      apiUrl: 'https://sandbox-app.vindi.com.br/api/v1/public/payment_profiles'
    }
  },
  Optimizely: {
    key: 'Mx1WxSGJ1wLZRYzj1YuA1',
  },
  Blotter: {
    notShow: {
      ETF: [],
      OTHERS: [],
      UNDERLYING: ['IGPM', 'PREFIXED'],
      CORPORATE_BONDS: ['CORPORATE_BONDS_CRI', 'CORPORATE_BONDS_CRA'],
      TANGIBLE: [],
    },
    cacheLoadPriceRequests: true,
    fields: {
      Fee: false,
    },
    hideSellAllList: ['TREASURY', 'CORPORATE_BONDS', 'SAVINGS', 'OTHERS'],
    hideSellList: ['OTHERS', 'TANGIBLE'],
    sellOnlyIfTradeParameter: ['CORPORATE_BONDS'],
    loadPriceList: ['TREASURY', 'FUNDQUOTE', 'STOCK', 'CRYPTOCURRENCY'],
    costsEnabled: true,
    savingsSaveAll: true
  },
  isMobile: window.screen.width < 768,
  idle: {
    time: 18000,
    timeAfterIdleBeforeLogout: 10,
  },
  serviceBaseUrl: '/api/',
  whitelistedDomains: [
    'api.dev.gorila.com.br',
    'app.gorila.com.br',
    'staging.gorila.com.br',
    'gorilainvest-prod.auth0.com',
    'dev.gorila.com.br',
    'gorilainvest.auth0.com',
  ],
  unstableLogin: false, // enable this option only on local environment to not wait WS connection and portfolio update
  socketUrl: '/',
  redirectOnLogout: false,
  mockedAuth0Response: null,
  mockedUser: null,
  mockedIdToken: null,
  mockName: '',
  mockedUrls: {
    get: [],
    post: [],
    put: [],
    delete: [],
    patch: [],
    /*
     * this is a sample how to mock responses. There's no 'sample' http method, so
     * there's no problem this sample still here
     */
    sample: [
      {
        // intercept function receive called url and must return a boolean
        interceptFn: (url) => url.indexOf('anyservice?myparmeters') !== -1,

        // if intercept function return true, then this response will be sent instead of call function
        getDataFn: (url) => 'this is a mocked response',
      },
    ],
  },
  features: {
    alertSimulationError: true,
    benchmark: {
      available: ['CDI', 'IBOVESPA', 'IPCA'],
      CDI: true,
      IBOVESPA: true,
      IPCA: true,
    },
    blotter: {
      loadPrice: true,
      sellEntirePosition: true,
      disableEdit: ['CURRENCY', 'OFFSHORE_ETF', 'OFFSHORE_FUNDQUOTE', 'OFFSHORE_REIT', 'OFFSHORE_STOCK']
    },
    emailVerification: false,
    freemium: false,
    gauges: false,
    helpMenu: true,
    manager: {
      allowUserCreation: true,
      enableClientNotification: true,
      hubspot: true,
      inviteClient: true,
      blockUiForUserInDebt: false
    },
    portfolio: {
      displayNetProfit: true,
      updateDebounceTime: 1500,
    },
    profitabilityChart: {
      displayNewChart: true,
    },
    tooltips: false,
    tools: {
      availableInvestments: {
        canEditDates: false,
        menu: false,
        page: false,
      },
      advancedProfitability: {
        menu: true,
        page: true,
      },
      B2BReports: {
        menu: false,
        page: false,
        modal: false,
      },
      cashFlow: {
        menu: true,
        page: true,
        beta: true
      },
      comparator: {
        menu: true,
        page: true,
        modal: false
      },
      office: {
        menu: true,
        page: true,
        modal: false
      },
      fee: {
        menu: true,
        page: true,
        modal: false,
      },
      genericAsset: {
        menu: true,
        page: true,
        modal: false
      },
      header: {
        page: true,
      },
      importFiles: {
        menu: true,
        page: true,
        modal: false,
        beta: false
      },
      portfolioEvent: {
        menu: true,
        page: true,
        premium: false,
      },
      report: {
        menu: true,
        page: true,
        reportDownloadButton: true,
        tabularReportDownloadButton: true,
      },
      tradeTool: {
        menu: true,
        page: true,
      },
    },
    trades: {
      edit: true,
    },
    user: {
      account: {
        menu: true,
        page: true,
        connect: {
          disconnect: true,
        },
      },
      payment: {
        menu: false,
        page: false,
        history: false,
        updateCard: false,
        cancelSubscription: false,
        printHistory: false,
      },
      preferences: {
        grossup: true
      },
      premium: true,
      termsAndPolicyInApp: true
    },
    wallet: {
      filter: true,
      search: true,
      displayPositionGenerators: true,
      displayCost: true,
    },
    tutorial: false,
    reloadDataOnDashbordPro: true,
    premiumAccess: {
      darf: {
        [ClientType.B2B]: PremiumPlanTier.KONG,
        [ClientType.B2B2C]: PremiumPlanTier.KONG,
        [ClientType.B2C]: PremiumPlanTier.KONG,
      },
      researchHouses: {
        [ClientType.B2B]: PremiumPlanTier.KONG,
        [ClientType.B2B2C]: PremiumPlanTier.KONG,
        [ClientType.B2C]: PremiumPlanTier.KONG,
      }
    }
  },
  assetHash: '',
  UnderlyingID: {
    CDI: 26,
    IPCA: 29,
    PREFIXED: 1959009,
    IGPM: 9727,
  },
  advisorLogoUrl: 'https://gorila-pro.s3.us-west-2.amazonaws.com/logo/dev/',
  comeCotasDays: [
    '2010-05-31',
    '2010-11-30',
    '2011-05-31',
    '2011-11-30',
    '2012-05-31',
    '2012-11-30',
    '2013-05-31',
    '2013-11-29',
    '2014-05-30',
    '2014-11-28',
    '2015-05-29',
    '2015-11-30',
    '2016-05-31',
    '2016-11-30',
    '2017-05-31',
    '2017-11-30',
    '2018-05-30',
    '2018-11-30',
    '2019-05-31',
    '2019-11-29',
    '2020-05-29',
    '2020-11-30',
    '2021-05-31',
    '2021-11-30',
    '2022-05-31',
    '2022-11-30',
    '2023-05-31',
    '2023-11-30',
    '2024-05-31',
    '2024-11-29',
    '2025-05-30',
    '2025-11-28',
    '2026-05-29',
    '2026-11-30',
    '2027-05-31',
    '2027-11-30',
    '2028-05-31',
    '2028-11-30',
    '2029-05-30',
    '2029-11-30',
    '2030-05-31',
    '2030-11-29',
  ],
  applicationDomainUrl: 'https://dev.gorila.com.br',
  proV2URL: 'https://dev-pro.gorila.com.br',
  bffUrl: 'https://k8s-dev.gorila.com.br/gorila-bff/v1',
};
