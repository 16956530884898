import { Injectable } from '@angular/core';
import { ClientType } from '@gorila-bot/user-data-store/lib/models/fund.model';
import { ApiService } from '@gorila-bot/gorila-base';
import { environment } from '@env/environment';

@Injectable()
export class ChatService {

  constructor(
    protected apiService: ApiService,
  ) { }

  private openZendesk(clientType: ClientType, windowRef?: Window): void {
    const window = windowRef as any;

    const zendeskScript = document.createElement('script');
    if (clientType === 'B2B') {
      zendeskScript.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=3ae95fa7-0475-4794-bb2f-896bc7b8a175');
    } else {
      zendeskScript.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=0e67446b-98f1-488e-a842-d774a543f3b4');
    }

    zendeskScript.setAttribute('id', 'ze-snippet');
    document.body.appendChild(zendeskScript);

    zendeskScript.onload = function () {
      window.zE('messenger', 'show');
    };
  }

  private closeZendesk(windowRef?: Window): void {
    const window = windowRef as any;

    if (!!window.zE) {
      window.zE('messenger', 'hide');
    }
  }

  private loadHubspotWidget(window: Window) {
    const widget = (window as any).HubSpotConversations.widget;

    if (widget.status().loaded) {
      widget.remove();
    } else {
      widget.load({ widgetOpen: true });
    }
  }

  private openHubspotChat(windowRef: Window, email: string, fullName: string): void {
    const window = windowRef as any;
    const firstName = fullName.split(' ')[0];
    const lastName = fullName.split(' ')[1] || null;

    const payload = {
      email, firstName, lastName
    };

    const path = 'support/hubspot/auth';
    const url = environment.serviceBaseUrl + path;

    this.apiService.doPost(url, {}, payload).subscribe(
      (data: {token: string}) => {

        window.hsConversationsSettings = {
          identificationEmail: email,
          identificationToken: data.token,
        };
        this.loadHubspotWidget(window);
      },
      (error) => console.error('Error getting user from Hubspot', error)
    );
  }

  public openChat(window: Window, clientType: ClientType): void {
    this.openZendesk(clientType, window);
  }

  public closeChat(window: Window, clientType: ClientType): void {
    this.closeZendesk(window);
  }
}
