import { OfficeToolRoutePath } from './../../../core/router/app-shell/manager/office/metadata';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { ClientType } from '@gorila-bot/gorila-front-models';
import {
  ACCOUNT_SETTINGS_PATH,
  AppShellFallbackRoutePath,
  B2BToolsMenuData,
  DashboardRoutePath,
  ManagerRoutePath,
  ToolsRoutePath,
  UserAccountsRoutePath,
  WalletRoutePath,
} from '@gorila/core/router';
import { TippyHelper } from '@gorilainvest/ui-toolkit/directives';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, fromPairs, isEmpty, KeyValuePair, path, update, isNil } from 'ramda';
import { BehaviorSubject, from, fromEvent } from 'rxjs';
import { debounceTime, delay, filter as filterOp, skip, take } from 'rxjs/operators';
import { getPremiumTierBadgeName, PremiumPlanTier } from '@gorila-bot/premium';

import { HeaderDropdownInput } from '../components';
import { helpLink } from '../menu.conf';
import { HeaderMenuItems } from '../menu.items';
import { ChatService } from './chat/chat.service';
import { HeaderMenuItemInputs } from './header-menu-item.inputs';
import { ActiveRoutes, FeatureConfigurationType, HeaderMenuUserData } from './models';

@Component({
  selector: 'header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderMenuComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {
  @Input() public advisorAsClient: boolean;
  @Input() public advisorMode: boolean;
  @Input() public brokerClientMode: boolean;
  @Input() public clientType: ClientType = ClientType.B2C;
  @Input() public userTier: PremiumPlanTier;
  @Input() public user: HeaderMenuUserData = { name: '', email: '' };
  private _toolsMenuData = [];
  @Input() public set toolsMenuData(data) {
    this._toolsMenuData = data;
    this.buildAll();
  }

  private _routerEvent: NavigationEnd = null;
  @Input() public set routerEvent(event: NavigationEnd) {
    if (event) {
      this._routerEvent = event;
      this.handleRouterEvents(event);
    }
  }
  public get routerEvent() {
    return this._routerEvent;
  }

  public _menuItems: HeaderMenuItemInputs[] = [];
  @Input() public set menuItems(items: HeaderMenuItemInputs[]) {
    this._menuItems = items || [];
    this.previousDisplayToolsDropdown = this._displayToolsDropdown$.value;
    this._displayToolsDropdown$.next(!!this._menuItems.find(it => it.id === HeaderMenuItems.TOOLS));
    this.buildAll(this._displayToolsDropdown$.value === this.previousDisplayToolsDropdown);
  }

  public _userMenuDropdown: HeaderMenuItemInputs[] = [];
  @Input() public set userMenuDropdown(dropdown: HeaderMenuItemInputs[]) {
    this._userMenuDropdown = (dropdown || []).map(data => {
      data.hasExternalLink = data.link.indexOf('http') !== -1;
      return data;
    });
  }

  private _activeDropdown = new BehaviorSubject<string>('');
  public readonly activeDropdown = this._activeDropdown.asObservable();
  public activeRoutes: ActiveRoutes = {};
  public activeTool = '';
  public tools: HeaderDropdownInput[] = [];

  private activeRoute = '';
  private dropdownMouseEnter: { [key: string]: BehaviorSubject<boolean> } = {};
  private dropdown: { [key: string]: boolean } = {};
  private tippyHelper: TippyHelper = new TippyHelper();
  private inited = false;
  private previousDisplayToolsDropdown = true;
  private _displayToolsDropdown$ = new BehaviorSubject<boolean>(true);
  public displayToolsDropdown$ = this._displayToolsDropdown$.asObservable();

  public constructor(
    private router: Router,
    private el: ElementRef,
    private chatService: ChatService
  ) { }

  public ngOnInit() {
    try {
      this.inited = true;
      this.buildAll(false);
    } catch (e) {
      console.warn(e);
    }
  }

  public ngAfterViewInit() {
    this.listenRouterContainer();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['advisorAsClient'] || changes['advisorMode']) {
      this.buildAll();
    }
  }

  public ngOnDestroy() {
    this.tippyHelper.destroy();
  }

  private updateActiveInMenuItems = () => this._menuItems.forEach((it) => {
    this.updateActiveInItem(it, this.activeRoute === it.link || !!this.dropdown[it.id]);
  })

  private buildActiveRoutes(conf: FeatureConfigurationType) {
    const toolPair = m => [m.featureName, m.page && m.routePath];
    const selectWithRoute = (m: string[]) => !!m[1];

    return {
      ...fromPairs<string>(<KeyValuePair<string, string>[]>[
        ['office', conf.office && OfficeToolRoutePath],
        ['accounts', conf.accounts && UserAccountsRoutePath],
        ['advisor', conf.advisor && ManagerRoutePath],
        ['dashboard', conf.client && DashboardRoutePath],
        ['tools', conf.client && ToolsRoutePath],
        ['usermenu', '_usermenu'],
        ['wallet', conf.client && WalletRoutePath]
      ].filter(selectWithRoute)),
      ...fromPairs<string>(
        <KeyValuePair<string, string>[]>this._toolsMenuData.map(toolPair).filter(selectWithRoute)
      )
    };
  }

  private buildAll(skipTippyRebuild = true) {
    if (!this.inited) {
      return;
    }
    this.setActiveRoute(this.router.url);
    const office = this.advisorMode;
    const accounts = environment.features.user.account.page && !this.advisorMode && !this.advisorAsClient;
    const advisor = this.advisorMode;
    const client = !this.advisorMode || this.advisorAsClient;
    const tools = !isEmpty(filter((_) => !!_, environment.features.tools));

    this.activeRoutes = this.buildActiveRoutes({ office, accounts, advisor, client, tools });
    this.tools = tools ? this.buildTools() : this.tools;
    if (!skipTippyRebuild) {
      setTimeout(() => this.initTippy(), 50);
    }
    this.updateActiveInMenuItems();
  }

  private buildTools() {
    const withMenuOnly = tool => tool && tool.menu;
    const toMenuEntry = tool => ({
      icon: tool.icon,
      id: `tools-menu-item-${tool.featureName}`,
      link: this.activeRoutes[tool.featureName],
      text: tool.text,
      cancelNavigation: tool.cancelNavigation,
      dataTracking: tool.dataTracking,
      hideTooltip: !!tool.hideTooltip,
      isBeta: !!tool.isBeta,
      displayPremiumIcon: !!tool.displayPremiumIcon,
      premiumIconPath: getPremiumTierBadgeName(tool.accessTier)
    });
    const viewFilter = this.getViewFilter();
    return this._toolsMenuData.filter(withMenuOnly).filter(viewFilter).map(toMenuEntry);
  }

  private getViewFilter() {
    const advisorViewFilter = tool => B2BToolsMenuData.some(data => data.featureName === tool.featureName);
    return this.advisorMode && !this.advisorAsClient
      ? advisorViewFilter
      : tool => !advisorViewFilter(tool);
  }

  private initTippy() {
    this.tippyHelper.destroy();
    from(['']).pipe(delay(250)).subscribe(() => {
      this._menuItems.filter((data) => !!data.tippyOptions).forEach((data) => {
        const target = this.el.nativeElement.querySelector('#' + data.id);
        if (!target) {
          return console.warn(`target doesn't #${data.id} exists!`);
        }
        this.tippyHelper.startTippy(data.id, data.tippyOptions, target, this.el.nativeElement);
      });
    });
  }

  private listenRouterContainer() {
    const containers = document.getElementsByClassName('router-container');
    if (!containers || !containers[0]) { return; }
    fromEvent(containers[0], 'click')
      .pipe(untilDestroyed(this))
      .subscribe(() => this._activeDropdown.next(this._activeDropdown.value || ''));
  }

  private handleRouterEvents(event: NavigationEnd) {
    this.setActiveRoute(this.router.url);
    this.buildAll(true);
    const route = event.url;
    try {
      this.activeTool = this.getActiveTool(route);
    } catch (error) {
      console.warn(error);
      this.activeTool = '';
    }
    this.setActiveRoute(route);
    if (this.activeRoute === ToolsRoutePath) {
      this._activeDropdown.next('');
    }
  }

  private getActiveTool(route: string = '') {
    if (route.includes(ToolsRoutePath)) {
      return this.getActiveToolFromRoute(route);
    }
    return this.getActiveToolFromToolLinks(route);
  }

  private getActiveToolFromRoute(route: string) {
    return route.split('//')[0] // get primary router outlet only
      .split('/') // get an array of route segments
      .reverse()[0] // reverse order to get the current tool segment
      .split(')')[0]; // remove router junk and query params if present
  }

  private getActiveToolFromToolLinks(route: string) {
    let active = '';
    const toolLinks = this.getToolLinks();
    toolLinks.forEach(link => {
      if (route.includes(link)) {
        const linkParts = link.split('/');
        active = linkParts[linkParts.length - 1];
      }
    });
    return active;
  }

  private getToolLinks() {
    return this.tools.filter(tool => !!tool.link).map(tool => tool.link);
  }

  private setActiveRoute(route: string) {
    const routePaths = [
      OfficeToolRoutePath,
      UserAccountsRoutePath,
      ToolsRoutePath,
      DashboardRoutePath,
      WalletRoutePath,
      ManagerRoutePath,
      AppShellFallbackRoutePath
    ];

    for (const routePath of routePaths) {
      if (route.includes(routePath)) {
        this.activeRoute = routePath;
        break;
      } else if (routePath === AppShellFallbackRoutePath) {
        this.activeRoute = AppShellFallbackRoutePath;
      }
    }

    if (route.includes(ACCOUNT_SETTINGS_PATH)) {
      this.activeRoute = '';
      return;
    }

    if (!this.activeRoute || this.activeRoute === '/app') {
      this.activeRoute = DashboardRoutePath;
    }
  }

  public reduce = (event) => (!!path(['type'], event) && !!this[`reduce${event['type']}`])
    ? this[`reduce${event['type']}`](event.data)
    : null

  private reduceMouseEnterIcon = (item) => this.updateActiveInItem(item, true);

  private reduceMouseLeaveIconStart = (item) => this.updateActiveInItem(item, false);

  private reduceUserMenuDropdownEvent(data: { mouseEvent: 'linkClick' | 'mouseEnter' | 'mouseLeave' }) {
    const { mouseEvent } = data;
    if (mouseEvent === 'linkClick') {
      this.dropdown['menu-item-tools'] = false;
      return ;
    }
    const enter = mouseEvent === 'mouseEnter';
    const userMenuRoute = this.activeRoutes.usermenu;
    if (!this.dropdownMouseEnter[userMenuRoute]) {
      this.dropdownMouseEnter[userMenuRoute] = new BehaviorSubject(true);
      return ;
    }
    this.dropdownMouseEnter[userMenuRoute].next(enter);
  }

  private reduceUserMenuEvent(data: { enter: boolean }) {
    const { enter } = data;
    const userMenuRoute = this.activeRoutes.usermenu;
    if (!enter && this.dropdownMouseEnter[userMenuRoute]) {
      this.dropdownMouseEnter[userMenuRoute].next(false);
      return;
    }
    if (enter) {
      if (this._activeDropdown.value === userMenuRoute) {
        return ;
      }
      if (!this.dropdownMouseEnter[userMenuRoute]) {
        this.dropdownMouseEnter[userMenuRoute] = new BehaviorSubject(true);
      }
      this.clearSubjectAfterLeavingDropdown(userMenuRoute);
      this._activeDropdown.next(userMenuRoute);
      return;
    }
    this._activeDropdown.next('');
  }

  private clearSubjectAfterLeavingDropdown(userMenuRoute: string) {
    this.dropdownMouseEnter[userMenuRoute].asObservable().pipe(
      skip(1),
      debounceTime(100),
      filterOp(d => !d),
      take(1)
    ).subscribe(
      () => {
        this._activeDropdown.next(
          this._activeDropdown.value === userMenuRoute ? '' : this._activeDropdown.value
        );
        this.dropdownMouseEnter[userMenuRoute] = null;
      }
    );
  }

  private reduceIconClicked = (item) => {
    if (item.id === 'menu-item-tools') {
      return ;
    }
    if (item.link === helpLink) {
      this.chatService.openChat(self, this.clientType);
      return;
    }
    this.dropdown['menu-item-tools'] = false;
    this.activeRoute = item.link;
    this.updateActiveInMenuItems();
    const baseUrl = this.router.url.substring(0, this.router.url.indexOf('/app/'));
    this.router.navigate([baseUrl + '/app/' + item.link]);
  }

  private reduceHeaderDropdownEvent = (data: { enter: boolean }) => {
    const { enter } = data;
    this.dropdown = !!enter ? { ['menu-item-tools']: true } : {};
    const item = this._menuItems.find((it) => it.id === 'menu-item-tools');
    this.updateActiveInItem(item, enter);
  }

  private isActive = (item, enter?) => !!enter || this.activeRoute === item.link || !!this.dropdown[item.id];
  private getIndex = (item) => this._menuItems.findIndex(dt => dt.id === item.id);
  private updateActiveInItem = (item, enter) => {
    if (!item) {
      console.warn('invalid empty data', item);
      return;
    }
    if (this.isActive(item, enter) === item.active) {
      return;
    }
    this._menuItems = update(this.getIndex(item), {
      ...item,
      active: enter,
      icon: this.setIcon(item.icon, enter)
    }, this._menuItems);
  }

  private setIcon = (icon, active) => (!!active)
    ? (icon.indexOf('_active.svg') === -1)
      ? icon.replace('.svg', '_active.svg')
      : icon
    : icon.replace('_active.svg', '.svg')
}
