import { Platform } from '@angular/cdk/platform';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { ApiService, SocketEventService, SocketService } from '@gorila-bot/gorila-base';
import { SERVICE_BASE_URL } from '@gorila-bot/gorila-base2';
import { AnalyticsService } from '@gorila-bot/gorila-front-utils';
import { FundType, UserDataActions, UserDataSelectors, UserDataState } from '@gorila-bot/user-data-store';
import { LoginStatusEnum, LoginStatusService } from '@gorila/core';
import {
  AppShellRoutePath,
  AuthConnectRoutePathUrl,
  AuthGorilaPROSignupRoutePathUrl,
  DashboardRoutePath,
  ManagerRoutePath,
  MobileWarningRoutePath,
} from '@gorila/core/router';
import { CookieService, UtilsService } from '@gorila/core/utils';
import { AppShellComponent } from '@gorila/pages/app-shell/app-shell.component';
import { LoginService } from '@gorila/shared';
import { LogService } from '@gorila/shared/services/log.service';
import { getUserMetadata } from '@gorila/utils/token';
import { select, Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { isEmpty, path, toString } from 'ramda';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, take } from 'rxjs/operators';

import { authStatusDefault, AuthStatusType } from '../models/auth.model';
import { AuthResult, AuthUser } from '../models/authnolock.model';
import {
  PORTFOLIO_ID_SESSION_STORAGE_KEY,
  TOKEN_KEY,
  URL_OAUTH_TOKEN_KEY,
  URL_OAUTH_TOKEN_KEY_ERROR,
} from '../models/storage-keys.model';
import { SignupError, SignUpUserData, USER_TYPE } from './auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private lockerSubject = new BehaviorSubject<any>({});
  private authStatus$ = new BehaviorSubject<AuthStatusType>(authStatusDefault);
  private firstLogin = false;
  private loggedFundType: FundType;
  private source: string;
  private subscriptions: { [s: string]: Subscription } = {};
  private isB2B2CSignup = false;
  private blockMobileAccess: boolean;
  private portfolioId: string;
  private proV2Url: string = environment.proV2URL;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private socketEventService: SocketEventService,
    private socketService: SocketService,
    private route: ActivatedRoute,
    private jwtHelper: JwtHelperService,
    private loginStatusService: LoginStatusService,
    private store: Store<UserDataState.State>,
    public platform: Platform,
    private apiService: ApiService,
    @Inject(SERVICE_BASE_URL) private serviceBaseUrl: string
  ) {
    this.route.queryParams
      .pipe(
        filter((data) => !isEmpty(data) || data.state),
        take(1)
      )
      .subscribe((queryParams: { [key: string]: any }) => {
        if (queryParams[PORTFOLIO_ID_SESSION_STORAGE_KEY]) {
          sessionStorage.setItem(PORTFOLIO_ID_SESSION_STORAGE_KEY, queryParams[PORTFOLIO_ID_SESSION_STORAGE_KEY]);
          this.portfolioId = queryParams[PORTFOLIO_ID_SESSION_STORAGE_KEY];
          this.isB2B2CSignup = true;
        }

        queryParams = { ...queryParams };
        if (queryParams.state) {
          localStorage.setItem('__state__', queryParams.state);
          return;
        }
        localStorage.setItem('__task_status__', queryParams.status);
        localStorage.setItem(
          '__task_token__',
          (queryParams.taskToken || '').replace(/ /g, '+')
        );
        localStorage.setItem(
          '__user_email__',
          (queryParams.email || '').replace(/ /g, '+')
        );
        delete queryParams.status;
        delete queryParams.taskToken;
        delete queryParams.email;

        localStorage.setItem('utm', JSON.stringify(queryParams));
        AnalyticsService.setEvent('utm', { ...queryParams });

        this.source = this.source || queryParams.source;
      });
    this.blockMobileAccess = !(location.pathname.includes('/m/') || location.pathname.endsWith('/m'));
    if (this.blockMobileAccess) {
      const lastroute = localStorage.getItem('lastroute') || '';
      this.blockMobileAccess = !(lastroute.includes('/m/') || lastroute.endsWith('/m'));
    }
    if (this.blockMobileAccess) {
      this.blockMobileAccess = (this.platform.IOS || this.platform.ANDROID);
    }

    if (this.router.url.includes(AuthConnectRoutePathUrl)) {
      let { id_token } = UtilsService.getParameters(this.router.url);

      id_token = id_token ? id_token : localStorage.getItem('id_token');

      if (id_token) {
        localStorage.setItem(URL_OAUTH_TOKEN_KEY, id_token);
      }

      this.handleLoginState();
      this.loginFromStorage();
      return;
    }

    if (!this.runMock()) {
      this.handleLoginState();
      this.tryAutoLogin();
    }
  }

  public authenticationCompleted(
    authResult: AuthResult,
    token: string,
    isLogin?: boolean
  ) {
    try {
      localStorage.setItem(TOKEN_KEY, token);
      AppShellComponent.manualLogin = true;
      this.onAutenticate(authResult, token, isLogin);

    } catch (e) {
      console.warn(e);
    }
  }

  public getLocker() {
    return this.lockerSubject;
  }

  public listenSocket(fundId: string) {
    this.socketService.emit('subscribe', fundId);
  }

  public muteSocket(fundId: string) {
    this.socketService.emit('unsubscribe', fundId);
  }

  public logout() {
    this.firstLogin = false;
    localStorage.removeItem('first-login');
    localStorage.removeItem('first-login-tracked');
    this.source = null;
    AnalyticsService.unsetUser();
    this.socketService.clear();
    this.loginStatusService.updateStatus(LoginStatusEnum.UserLogoutStarted);
    this.logoutDone();
  }

  public authenticated(): boolean {
    return this.isMocked() ? true : !!this.getAuthToken();
  }

  public displayError(error: any) {
    this.loginStatusService.updateStatus(LoginStatusEnum.Error);
    this.lockerSubject.next({
      flashMessage: {
        type: 'error',
        text:
          !error['error_description'] ||
            toString(error['error_description']) === ''
            ? 'Unknown Error'
            : error['error_description'],
      },
    });
  }

  private handleLoginState() {
    // do not unsubscribe from this listener. It must listen while app run

    this.authStatus$.pipe(filter(data => !!data)).subscribe(data => {
      LogService.loginStatusLog('$$$', { ...data });

      if (!!data.error) {
        return this.handleError(data);
      }
      if (!data.auth0) {
        return this.handleAuth0();
      }
      if (!data.signup) {
        return this.handleSignup();
      }
      if (localStorage.getItem('first-login') === 'true') {
        this.firstLogin = true;
      }
      if (!data.login) {
        if (environment.unstableLogin) {
          this.changeAuthStatus('login', true);
        }
        return this.getUserData();
      }

      if (!data.ws && !environment.unstableSocket) {
        if (environment.unstableLogin) {
          this.changeAuthStatus('ws', true);
        }
        return this.handleSocket();
      }

      this.handleUserLogged();
    });
  }

  private tryAutoLogin() {
    if (!this.loginByUrl()) {
      this.loginFromStorage();
    }
  }

  private handleError(data) {
    const errorStatus = path(['error', 'status'], data);
    const status = toString(errorStatus || data.status);
    switch (status) {
      case '401': {
        data.error = {
          ...data.error,
          error_description: !!path(['error', 'statusText'], data)
            ? data.error.statusText
            : 'Auth0TokenNotRecognized',
        };
        break;
      }
    }
    this.displayError(data.error);
  }

  private handleAuth0() {
    this.lockerSubject.next({});
    this.loginStatusService.updateStatus(
      LoginStatusEnum.Auth0NotLogged,
      null,
      true
    );
  }

  private handleSignup() {
    this.firstLogin = true;
    localStorage.setItem('first-login', `${this.firstLogin}`);

    if (environment.unstableLogin) {
      localStorage.setItem('ApiChecker', 'true');
    }
    this.changeAuthStatus('signup', true);
    this.getUserData();
  }

  private handleSocket() {
    if (!this.subscriptions['wsToken']) {
      this.subscriptions['wsToken'] = this.store
        .pipe(
          select(UserDataSelectors.selectFeatureUserToken),
          filter((token) => !!token)
        )
        .subscribe((token) => {
          this.socketService.clear();
          this.socketService.authenticate(token, { forceNew: true }, () => {
            const fundId = this.loginService.getFundID();
            this.socketService.emit('subscribe', fundId);
          });
        });
    }
    if (!this.subscriptions['ws']) {
      this.subscriptions[
        'ws'
      ] = this.socketEventService
        .getWSActivityObserver()
        .subscribe((data: any) => {
          if (data !== true) {
            return;
          }
          this.changeAuthStatus('ws', true);
        });
    }

    if (!this.subscriptions['wsError']) {
      this.subscriptions[
        'wsError'
      ] = this.socketEventService
        .getSocketErrorObserver()
        .subscribe((wsStatus) => {
          if (!!wsStatus) {
            LogService.loginStatusLog('$$$ wsError [doing logout]: ', wsStatus);
            this.logout();
          }
        });
    }
  }

  private getUserData() {
    this.subscriptions['user'] = this.loginService
      .getUserData()
      .pipe(
        concatMap(userData =>
          this.loginService
            .getSubscriptionData()
            .pipe(map(subscriptionData => ({ userData, subscriptionData })))
        ),
        catchError((error: any): Observable<any> => {
          console.warn('user data error', error);
          this.authStatus$.next({
            ...authStatusDefault,
            error: { ...error, error_description: 'login/user failure' },
          });
          localStorage.setItem('social_error', 'LOGIN.ERROR.SOCIAL_EMAIL');
          return throwError(error);

        })
      )
      .subscribe(userAndSubscriptionData => {
        if (
          userAndSubscriptionData.userData.loginType === 'B2B' &&
          !this.isB2B2CSignup
        ) {
          (window as any).dataLayer.push({
            'user_id' : userAndSubscriptionData.userData.id,
          });
          this.trackUserLogin(userAndSubscriptionData);
          window.location.href = `${environment.proV2URL}/#${localStorage.getItem('id_token')}`;
          return;
        }
        this.loginCompleted(userAndSubscriptionData);
      });
  }

  private handleUserLogged() {
    this.loginStatusService.updateStatus(LoginStatusEnum.UserLogged);
    this.navigateToRoute();
  }

  private loginCompleted(loginData) {
    const { userData } = loginData;
    const portfolioId = Number(sessionStorage.getItem(PORTFOLIO_ID_SESSION_STORAGE_KEY));
    const impersonatedUserData = userData.children.find(user => user.id === portfolioId);
    const impersonatedData = {
      FundId: portfolioId,
      FundTypeName: 'CLIENT',
      ClientType: 'B2C',
      Trader: {
        FundID: this.portfolioId,
        TraderName: impersonatedUserData ? impersonatedUserData.name : userData.name,
        TraderEmail: impersonatedUserData ? impersonatedUserData.email : userData.email,
        Modified: userData.updated,
        TraderID: userData.legacyId,
      },
    } as const;

    const newLoginData = {
      FundId: userData.id,
      FundTypeName: userData.type === 'INDIVIDUAL' ? 'CLIENT' : userData.type === 'ADVISOR' ? 'ADVISOR' : 'UNKNOWN' as FundType,
      ClientType: userData.type === 'INDIVIDUAL' ? 'B2C' : userData.type === 'ADVISOR' ? 'B2B' : 'B2B2C',
      Trader: {
        FundID: userData.id,
        TraderName: userData.name,
        TraderEmail: userData.email,
        Modified: userData.updated,
        TraderID: userData.legacyId,
      },
      Wallets: userData.wallets && userData.wallets.map(wallet => (Number(wallet.id))),
    };

    if (this.portfolioId) {
      this.store.dispatch(new UserDataActions.UserUpdateAppMetadata({
        portfolioRelations: [],
        tier: undefined
      }));
      this.store.dispatch(new UserDataActions.UserUpdateFromUserMe({
        name: userData.name,
        email: userData.email
      }, {
        traderName: userData.name,
        traderEmail: userData.email
      }));
      this.store.dispatch(new UserDataActions.UserSetFundData(impersonatedData));
      this.loggedFundType = impersonatedData['FundTypeName'];

      this.trackUserLogin(loginData);
      this.setFundId(impersonatedData['FundId']);
      localStorage.setItem('ApiChecker', 'true');
      localStorage.removeItem('__user_email__');
      localStorage.removeItem('social_error');
      this.changeAuthStatus('login', true);
      localStorage.setItem('FundID', this.portfolioId);
    } else if (loginData) {
      newLoginData.Trader.TraderName = userData.name;
      newLoginData.Trader.TraderEmail = userData.email;
      this.store.dispatch(new UserDataActions.UserUpdateAppMetadata({
        portfolioRelations: userData.children.map(child => child.id),
        tier: undefined
      }));
      this.store.dispatch(new UserDataActions.UserUpdateFromUserMe({
        name: userData.name,
        email: userData.email
      }, {
        traderName: userData.name,
        traderEmail: userData.email
      }));
      this.store.dispatch(new UserDataActions.UserSetFundData(newLoginData));
      this.loggedFundType = newLoginData['FundTypeName'];
      this.trackUserLogin(loginData);
      this.setFundId(newLoginData['FundId']);
      localStorage.setItem('ApiChecker', 'true');
      localStorage.removeItem('__user_email__');
      localStorage.removeItem('social_error');
      this.changeAuthStatus('login', true);
    }
  }

  private useOrgIdFromUserData = (userData) => {
    let firstOrgId = '';
    firstOrgId = userData.organizations[0].id;

    switch (userData.loginType) {
      case 'B2B': {
        const enterpriseOrg = userData.organizations.find(org => org.type === 'ENTERPRISE');
        if (enterpriseOrg.id) {
          return enterpriseOrg.id;
        }
        return firstOrgId;
      }
      case 'B2C':
        return firstOrgId;
      case undefined:
      default:
        return '';
    }
  }

  /**
   * Send login event to AnalyticsService
   * @param legacyUserInfo an object containing:
   * - userData: /user/me
   * - subscriptionData : /premium/subscription
   */
  private trackUserLogin(legacyUserInfo) {
    this.apiService
      .getData(
        `${this.proV2Url}/api/me`,
        { 'Authorization': `Bearer ${localStorage.getItem('id_token')}`.toString()},
        false,
        null,
        false
      )
      .subscribe((userInfo) => {
        const urlUtmParams = this.getUrlUtmParams();
        const { userData: legacyUserData, subscriptionData } = legacyUserInfo;
        const organization_id = this.useOrgIdFromUserData(userInfo);
        const eventInfo = {
          name: legacyUserData.name,
          email: legacyUserData.email,
          user_type: legacyUserData.type,
          organization_id,
          external_portfolio_id: legacyUserData.externalId,
          plan: legacyUserData.type === USER_TYPE.ADVISOR ? 'PRO' : subscriptionData.currentPlan.planName,
          is_b2b2c: legacyUserData.parents.some(parent => parent.type === USER_TYPE.ADVISOR),
          user_id: legacyUserData.userId,
          auth_method: legacyUserData.providerConnection,
          payment_method: subscriptionData.gateway.type || null,
          payment_frequency: subscriptionData.currentPlan.period || null,
          trial: !!subscriptionData.isTrial,
          ...urlUtmParams,
        };
        AnalyticsService.setEvent('user_login', eventInfo);
      });
  }

  private getUrlUtmParams = (): object => {
    try {
      const utm = localStorage.getItem('utm');
      const utmParams = (!utm) ? {} : JSON.parse(utm);
      const mappedUtmParams = {};
      for (const i in utmParams) {
        if (!utmParams[i]) {
          continue;
        }
        const param = `utm_${i}`.replace('utm_utm_', 'utm_');
        mappedUtmParams[param] = utmParams[i];
      }
      return mappedUtmParams;
    } catch (error) {
      console.warn(error);
      return {};
    }
  }

  private loginFromStorage() {
    const token = this.getAuthToken();
    if (this.jwtHelper.isTokenExpired(token)) {
      localStorage.removeItem('id_token');
      return false;
    }
    const authResult = !!token ? this.jwtHelper.decodeToken(token) : null;
    if (!!authResult) {
      const oauthToken = localStorage.getItem(URL_OAUTH_TOKEN_KEY);
      if (oauthToken) {
        this.loginService
          .linkAuth0Account(oauthToken, token)
          .pipe(take(1), finalize(() => this.onAutenticate(authResult, token, true)))
          .subscribe(() => { }, (err) => { console.log('err', err); this.linkAuth0AccountError(oauthToken); });
      } else {
        this.onAutenticate(authResult, token, true);
      }
      return true;
    }
    return false;
  }

  private linkAuth0AccountError(oauthToken) {
    localStorage.removeItem(URL_OAUTH_TOKEN_KEY);
    localStorage.setItem(URL_OAUTH_TOKEN_KEY_ERROR, oauthToken);
  }

  private loginByUrl() {
    try {
      const p = UtilsService.getParameters(this.router.url);
      if (p['first']) {
        CookieService.setCookie('first_login', 1);
      }

      if (!p[TOKEN_KEY]) {
        AppShellComponent.manualLogin = true;
        return false;
      }

      const token = `${p['token_type'] || 'Bearer '}${p[TOKEN_KEY]}`;
      const authResult = this.jwtHelper.decodeToken(token);
      const userMetadata = getUserMetadata(authResult);
      const isLogin = !userMetadata.first_login;
      if (window.location.hash) {
        window.location.hash = undefined;
      }
      AnalyticsService.setUser(authResult['user_id'] || authResult['sub']);
      this.linkAuth0Account(p[TOKEN_KEY]);
      this.authenticationCompleted(authResult, p[TOKEN_KEY], isLogin);
      return true;
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  private linkAuth0Account(token) {
    const oauthToken = localStorage.getItem(URL_OAUTH_TOKEN_KEY);
    if (!oauthToken) {
      return;
    }
    this.loginService
      .linkAuth0Account(oauthToken, token)
      .pipe(take(1))
      .subscribe(
        () => { },
        (err) => {
          console.error('err', err);
          this.linkAuth0AccountError(oauthToken);
        }
      );
  }

  private onAutenticate(
    authResult: AuthResult,
    token: string,
    isLogin?: boolean,
  ) {
    // starting store
    this.store.dispatch(new UserDataActions.UserLogin());
    this.store.dispatch(
      new UserDataActions.UserSetAuthData(authResult, token)
    );

    // changing status
    this.authStatus$.next({
      ...this.authStatus$.getValue(),
      auth0: true,
      error: null,
      signup: isLogin || false,
    });
    this.loginStatusService.updateStatus(
      LoginStatusEnum.ConnectingWithServices
    );
  }

  private changeAuthStatus(key: string, value: boolean) {
    const authStatus = this.authStatus$.getValue();
    // FIXME double socket connection
    // without the IF STMT, user will be redirect back to dash
    if (authStatus[key] === value) { return; }
    this.authStatus$.next({ ...authStatus, [key]: value });
  }

  private getUserTypeFromURL(): 'B2C' | 'B2B' | 'B2B2C' {
    return (this.isB2B2CSignup)
      ? 'B2B2C'
      : this.router.url.indexOf(AuthGorilaPROSignupRoutePathUrl) !== -1 ? 'B2B' : 'B2C';
  }

  private runMock() {
    if (!this.isMocked()) {
      return false;
    }
    console.warn('$$$ Running mocked services!');

    this.authenticationCompleted(
      environment.mockedAuth0Response,
      environment.mockedIdToken
    );
    this.loginCompleted(environment.mockedUser);
    this.authStatus$.next({
      error: null,
      login: true,
      signup: true,
      ws: true,
      auth0: true,
    });
    this.handleUserLogged();
    return true;
  }

  private isMocked() {
    return !environment.production && !!environment.enableMockedData;
  }

  public getAuthToken() {
    // Check if there's an unexpired JWT
    // It searches for an item in localStorage with key == 'id_token'
    return this.jwtHelper.tokenGetter();
  }

  private logoutDone() {
    try {
      Sentry.configureScope(scope => scope.setUser(null));
      this.loggedFundType = null;
      this.loginService.resetFundID();
      this.loginStatusService.updateStatus(LoginStatusEnum.Auth0NotLogged, {
        needLocker: 1,
      });
      this.authStatus$.next(authStatusDefault);
      this.clearSubscriptions();
    } catch (e) {
      console.warn(e);
    }
  }

  private clearSubscriptions() {
    for (const s in this.subscriptions) {
      if (!!this.subscriptions[s] && !this.subscriptions[s].closed) {
        this.subscriptions[s].unsubscribe();
      }
    }
    this.subscriptions = {};
  }

  private navigateToRoute() {
    if (this.portfolioId) {
      return this.router.navigateByUrl(`${AppShellRoutePath}/${DashboardRoutePath}`);
    }
    const isAdvisor = this.loggedFundType === 'ADVISOR';
    if (AppShellComponent.manualLogin && this.blockMobileAccess && !isAdvisor) {
      return this.router.navigateByUrl(`${AppShellRoutePath}/${MobileWarningRoutePath}`);
    }
    if (isAdvisor) {
      return this.router.navigate([AppShellRoutePath, ManagerRoutePath], {
        relativeTo: this.route.children[0],
      });
    }
    const route = localStorage.getItem('lastroute');
    if (!!route) {
      return this.router.navigateByUrl(route);
    }
    this.router.navigate([AppShellRoutePath, DashboardRoutePath], {
      relativeTo: this.route.children[0],
    });
  }

  private setFundId(fundId) {
    if (!!fundId) {
      this.loginService.setFundId(fundId);
      this.store.dispatch(new UserDataActions.UserSetCurrentFund(fundId));
    }
  }

  public signUpUser(user: AuthUser, userType: USER_TYPE): Observable<any> {
    const isGorilaPRO = userType === USER_TYPE.ADVISOR;
    const postData: SignUpUserData = {
      name: user.name,
      username: user.email,
      password: user.password,
      type: isGorilaPRO ? USER_TYPE.ADVISOR : USER_TYPE.INDIVIDUAL,
    };
    const url = `${this.serviceBaseUrl}user/signup`;

    const signupFailed = (err: HttpErrorResponse): any => {
      console.error(err);

      const error = err.error;
      const e: SignupError = { error: true, text: '' };

      switch (error.code) {
        case 400:
          e.text = error.constraints[0].includes('password must be longer') ? 'Invalid Password' : 'Invalid e-mail or empty password';
          break;
        case 409:
          e.text = 'User already exists';
          break;
        default:
          e.text = 'signup failure';
      }

      throw e;
    };

    const signUpuser = this.apiService.doPost(url, {}, postData).pipe(catchError(signupFailed));
    return signUpuser;
  }
}
