import { Route } from '@angular/router';
import { environment } from '@env/environment';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';
import { ReportToolRoutePath } from './metadata';

export const ReportToolMenuData = {
  featureName: 'report',
  routePath: ReportToolRoutePath,
  icon: 'Nav/Advanced-Tools/Report-White',
  text: 'TOOLS_DROPDOWN.REPORT',
  cancelNavigation: environment.features.tools.report.modal
};

export const ReportToolRoute: Route = {
  data: {
    title: 'Relatório por Período'
  },
  path: ReportToolRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: './ui/report-pdf-viewer/report-pdf-viewer.module#ReportPdfViewerModule'
};
