import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cpfCnpj'
})
export class CpfCnpjPipe implements PipeTransform {

    public transform(cpfCnpj: string): string {
        if (cpfCnpj) {
            const value = cpfCnpj.toString().replace(/\D/g, '');
            let cpfCnpjFormatted = '';
            if (value.length > 11 ) {
              cpfCnpjFormatted = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
            } else if (value.length > 9) {
                cpfCnpjFormatted = value.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{0,5})/, '$1.$2.$3-$4');
            } else if (value.length > 5) {
                cpfCnpjFormatted = value.replace(/^(\d{3})?(\d{3})?(\d{0,5})/, '$1.$2.$3');
            } else if (value.length > 2) {
                cpfCnpjFormatted = value.replace(/^(\d{3})?(\d{0,5})/, '$1.$2');
            } else {
              return cpfCnpj;
            }
            return cpfCnpjFormatted;
        }
        return '';

    }

}
