import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BillingFacade, PremiumPlanTier } from '@gorila-bot/billing-store';
import { ClientType } from '@gorila-bot/gorila-front-models';
import { selectFeatureByPath, UserDataKey } from '@gorila-bot/gorila-store';
import {
  Fund,
  FundIdType,
  TraderServer,
  UserDataActions,
  UserDataSelectors,
  UserDataState,
} from '@gorila-bot/user-data-store';
import {
  ACCOUNT_SETTINGS_PATH,
  B2BToolsMenuData,
  DetailedPositionReportToolMenuData,
  ManagerRoutePath,
  ToolsMenuData,
  OfficeToolRoutePath,
  ImportSpreadsheetRoutePath
} from '@gorila/core/router';
import { ReduceEvent } from '@gorila/core/utils';
import { AuthService } from '@gorila/pages/auth';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { CashFlowMenuData } from 'src/app/core/router/app-shell/tools/cash-flow';
import { DARFMenuData } from 'src/app/core/router/app-shell/tools/DARF';
import { ResearchHousesEventToolMenuData } from 'src/app/core/router/app-shell/tools/research-houses';
import { path } from 'ramda';
import { FeaturePageEnv } from '@env/models/feature-env.model';
import { environment } from '@env/environment';
import { PremiumService } from '@gorila-bot/premium';
import { FeatureToggleService } from '@gorila-bot/gorila-toggle';

import { HeaderMenuUserData } from './header-menu/models';
import { getMenuItems, getUserMenuDropdown } from './menu.conf';

// TODO - add interface to tools
const completeToolsMenuData: Record<string, any>[] = [...ToolsMenuData, ...B2BToolsMenuData];

const FALLBACK_ALLOWED_CLIENT_TYPES = ['B2C', 'B2B2C', 'B2B'];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnDestroy, OnInit {
  @HostBinding('class.advisor-as-client') public advisorAsClientClassBinding = false;

  private _advisorAsClient = new BehaviorSubject<boolean>(false);
  public advisorAsClient = this._advisorAsClient.asObservable();
  public menuItems = [];
  public userMenuDropdown = [];
  public advisorMode$: Observable<boolean>;
  public advisorClient$: Observable<Fund>;
  public brokerClientMode$: Observable<boolean>;
  public routerEvents$: Observable<NavigationEnd>;
  public clientType: ClientType = ClientType.B2C;
  public isMobile = false;
  public user$: Observable<HeaderMenuUserData>;
  public toolsMenuData = completeToolsMenuData;
  public isKongUser = false;
  public isPoppyUser = false;

  private brokerClientMode: boolean;
  private _advisorMode: boolean;
  private currentRoute = '';
  private toolsMenuBase = completeToolsMenuData;
  private menuItemToggleAvailables: { [s: string]: string };
  private userTier: PremiumPlanTier = PremiumPlanTier.FREE;

  private readonly excludedFeaturesB2B = [
    DARFMenuData.featureName,
    ResearchHousesEventToolMenuData.featureName,
  ];

  private readonly excludedFeatures = [
    ...this.excludedFeaturesB2B,
    CashFlowMenuData.featureName,
  ];

  public hide$ = this.store.pipe(
    select(UserDataSelectors.selectHidePatrimony),
    untilDestroyed(this),
    distinctUntilChanged()
  );

  public constructor(
    private store: Store<UserDataState.State>,
    private translate: TranslateService,
    private billingFacade: BillingFacade,
    private featureToggleService: FeatureToggleService,
    private router: Router,
    private authService: AuthService,
    private platform: Platform,
    private premium: PremiumService
  ) {
    this.isMobile = this.platform.ANDROID || this.platform.IOS;
    this.menuItemToggleAvailables = this.featureToggleService.getAllFeatureVariables('web_header_menu');
  }

  public ngOnInit() {
    if (this.isMobile) {
      return;
    }

    const userDataObservable = this.initUserDataObservables();

    this.initAdvisorObservables();
    this.initBrokerClientObservable();
    this.handleRouterEvents();
    userDataObservable
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this.buildToolsMenu();
    });
  }



  private initAdvisorObservables() {
    this.advisorMode$ = this.store.pipe(
      select(UserDataSelectors.selectFeatureCurrentLoggedFundData),
      map((fundData: Fund) => fundData.fundTypeName === 'ADVISOR'),
      tap(advisorMode => {
        this._advisorMode = advisorMode;
        this.buildMenuItems();
        this.buildUserMenuDropdown();
      })
    );
    this.advisorClient$ = combineLatest([
      this.advisorMode$,
      this.store.pipe(select(UserDataSelectors.selectFeatureLoggedUserFundId)),
      this.store.pipe(select(UserDataSelectors.selectFeatureCurrentFundData))
    ]).pipe(
      untilDestroyed(this),
      map(([advisor, logFundId, currFundData]: [boolean, FundIdType, Fund]) =>
        [this.isAdvisorAsClient(advisor, logFundId, currFundData.fundId), currFundData]),
      tap(([advisorAsClient, _]: [boolean, Fund]) => {
        this._advisorAsClient.next(advisorAsClient);
        this.advisorAsClientClassBinding = advisorAsClient;
        this.buildMenuItems();
      }),
      map(([_, fundData]) => fundData)
    );
  }

  private isAdvisorAsClient(advisor: boolean, loggedFundId: string, currentFundId: string) {
    return advisor && loggedFundId !== currentFundId;
  }

  private initBrokerClientObservable() {
    this.brokerClientMode$ = this.store.pipe(
      untilDestroyed(this),
      select(UserDataSelectors.selectBrokerClientMode),
      tap(brokerClientMode => {
        this.brokerClientMode = brokerClientMode;
        this.buildMenuItems();
        this.buildUserMenuDropdown();
      }),
    );
  }

  private initUserDataObservables() {
    this.user$ = this.store.pipe(
      select(UserDataSelectors.selectTraderData),
      map((trader: TraderServer) => ({ name: trader.TraderName, email: trader.TraderEmail })),
      untilDestroyed(this),
    );

    const clientType$ = this.store.pipe(
      map(state => selectFeatureByPath(state, [UserDataKey, 'clientType'])),
      take(1),
      untilDestroyed(this)
    );

    const userTier$ = this.premium.userTier$.pipe(
      filter(tier => tier !== null),
      untilDestroyed(this)
    );

    return combineLatest([
      clientType$,
      userTier$
    ])
    .pipe(
      tap(([clientType, userTier]) => {
        this.clientType = clientType;
        this.userTier = userTier;
        this.isKongUser = userTier === PremiumPlanTier.KONG;
        this.isPoppyUser = userTier === PremiumPlanTier.POPPY;
        this.filterToolsMenu();
        this.checkPremiumAccess();
      }),
      untilDestroyed(this)
    );
  }

  private handleRouterEvents() {
    this.routerEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        this.currentRoute = event.url;
        this.buildMenuItems();
        if (this.currentFundShouldBeAdvisor()) {
          this.changeToAdvisorFund();
        }
      })
    );
  }

  private currentFundShouldBeAdvisor() {
    return this.currentRoute.indexOf(ManagerRoutePath) !== -1
      || this.currentRoute.indexOf(ImportSpreadsheetRoutePath) !== -1
      || this.currentRoute.indexOf(OfficeToolRoutePath) !== -1
      || (this._advisorMode
        && (this.currentRoute.indexOf(ACCOUNT_SETTINGS_PATH) !== -1));
  }

  private changeToAdvisorFund() {
    this.store.pipe(
      select(UserDataSelectors.selectFeatureCurrentFundId),
      take(1),
    ).subscribe(currentFundId => {
      this.authService.muteSocket(currentFundId);
    });
    this.setAdvisorFundAsActive();
  }

  private setAdvisorFundAsActive() {
    const dispatchSetCurrentFund =
      (fundId: FundIdType) => this.store.dispatch(new UserDataActions.UserSetCurrentFund(fundId));
    this.store.pipe(select(UserDataSelectors.selectFeatureLoggedUserFundId), take(1))
      .subscribe(dispatchSetCurrentFund);
  }

  private toggleMainMenuItems(): void {
    this.menuItems = this.menuItems.filter( menuItem => {
      const canShow = path([menuItem.id, 'canShow'], this.menuItemToggleAvailables);
      return canShow === undefined || canShow === true;
    });
  }

  private buildMenuItems = () => {
    this.menuItems = getMenuItems(
        {
          advisor: this._advisorMode,
          brokerClient: this.brokerClientMode,
          client: !this._advisorMode || this._advisorAsClient.value,
          translate: this.translate,
          route: this.currentRoute,
        },
      );
    this.toggleMainMenuItems();
  }

  private buildToolsMenu() {
    this.toolsMenuBase = completeToolsMenuData.map((tool) => {
      // get config from environment config
      const envFeatureToggle = path<FeaturePageEnv>(['features', 'tools', tool.featureName], environment);
      const accessTier = path<PremiumPlanTier>(['features', 'premiumAccess', tool.featureName, this.clientType], environment);
      if (!!envFeatureToggle) {
        return {
          ...tool,
          menu: !!envFeatureToggle.menu,
          page: !!envFeatureToggle.page,
          displayPremiumIcon: !!envFeatureToggle.premium ? envFeatureToggle.premium : (accessTier > this.userTier),
          accessTier: !envFeatureToggle.premium ? 0 : accessTier,
          isBeta: !!envFeatureToggle.beta,
          clientTypes: envFeatureToggle.clientTypes || FALLBACK_ALLOWED_CLIENT_TYPES,
        };
      } else {
      // get config from remote feature toggle service
      const remoteFeatureToggle: FeaturePageEnv = this.featureToggleService.getFeatureVariable('advanced-tools', tool.featureName);

      if (!remoteFeatureToggle) {
        return tool;
      }

      return {
        ...tool,
        menu: !!remoteFeatureToggle.menu,
        page: !!remoteFeatureToggle.page,
        displayPremiumIcon: accessTier > this.userTier,
        accessTier,
        cancelNavigation: !!remoteFeatureToggle.modal,
        isBeta: !!remoteFeatureToggle.beta,
        clientTypes: remoteFeatureToggle.clientTypes || FALLBACK_ALLOWED_CLIENT_TYPES,
      };

      }
    });
    this.toolsMenuBase = this.filterExcludedFeatures();
    this.toolsMenuData = this.toolsMenuBase;
  }

  private filterExcludedFeatures() {
    const isB2BClient = this.clientType === 'B2B';
    const excluded = isB2BClient ? this.excludedFeaturesB2B : this.excludedFeatures;

    const filteredTools = this.toolsMenuBase.filter(
      ({ featureName }) => !this.isKongUser && !excluded.includes(featureName)
    );

    return filteredTools.length > 0 ? filteredTools : this.toolsMenuBase;
  }

  private buildUserMenuDropdown = () => this.userMenuDropdown = getUserMenuDropdown(
    this.translate,
    this._advisorMode,
    this.brokerClientMode
  )

  private checkPremiumAccess() {
    this.toolsMenuData = this.toolsMenuBase.map((tool) => ({
      ...tool,
      displayPremiumIcon: tool.accessTier > this.userTier,
    }));
  }

  private filterToolsMenu() {
    this.toolsMenuData = this.toolsMenuBase.filter(
      ({ clientTypes }) => (clientTypes || FALLBACK_ALLOWED_CLIENT_TYPES).includes(this.clientType));
  }

  /**
   * @deprecated cash flow will be displayed to all users
   */
  private filterCashFlowFromPoppyUsers(tools) {
    return this.userTier < PremiumPlanTier.KONG
      ? tools.filter(data => data.featureName !== CashFlowMenuData.featureName)
      : tools;
  }

  public reduce = (data) => ReduceEvent(this, data);

  private reduceHideChange() {
    this.store.dispatch(new UserDataActions.ToggleHidePatrimony());
  }

  public ngOnDestroy() { }
}
