<ng-container *ngIf="data">
  <gor-partner-header [step]="data.dataStepped ? step : null" [isBeta]="data.isBeta"
    [partnerLogoName]="data.partnerLogoName" [partnerName]="data.title"
    [partnerType]="'_'+data.userAccountData?.providerType" [showCloseButton]="true"
    [helpLink]="data.dataStepped ? null : data.urlGuia"></gor-partner-header>

  <ng-container [ngSwitch]="step">
    <div *ngSwitchCase="1" [@stepChangeAnimation]>
      <div class="b3-partner__body">
        <h6 class="b3-partner__items-title" translate>
          B3_PARTNER.HAVE_ACCOUNT.TITLE_A
        </h6>
        <span class="b3-partner__items-subtitle" translate>
          B3_PARTNER.HAVE_ACCOUNT.SUBTITLE_A <strong translate>B3_PARTNER.HAVE_ACCOUNT.SUBTITLE_B</strong>
        </span>
      </div>
      <div class="b3-partner__footer-disclaimer-step-1">
        <gor-icon class="b3-partner__item-icon color-filter--alert" iconFolder="Platform/Information" icon="Attention"
          width="15px" height="15px">
        </gor-icon>
        <p [innerHTML]="'B3_PARTNER.HAVE_ACCOUNT.FOOTER_DISCLAIMER'|translate"></p>
      </div>
    </div>
    <div *ngSwitchCase="2" [@stepChangeAnimation]>
      <div class="b3-partner__body">
        <h6 class="b3-partner__items-title" translate>
          B3_PARTNER.CONNECTION.HEADER_TITLE
        </h6>
        <ul class="b3-partner__items">
          <ng-container *ngFor="let item of [1,2,3,4,5]">
            <li class="b3-partner__item">
              <gor-icon class="b3-partner__item-icon" iconFolder="Platform/Actions" icon="Check-Green" width="16px"
                height="14px"></gor-icon>
              <span [innerHTML]="('B3_PARTNER.CONNECTION.LIST_ITEM_A_'+item)|translate"></span>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="b3-partner__footer-disclaimer">
        <mat-checkbox [(ngModel)]="check">
          <gor-icon iconFolder="Selectors/Checkbox" [icon]="check?'Selected-Green':'Unselected'" height="16px"
            width="16px"></gor-icon>
        </mat-checkbox>
        <p [innerHTML]="'B3_PARTNER.CONNECTION.CONNECTION_TYPE'|translate"></p>
      </div>
    </div>
    <div *ngSwitchCase="3" [@stepChangeAnimation]>
      <div class="b3-partner__body">
        <div class="b3-partner__warn-container">
          <div class="b3-partner__warn-title">
            <gor-icon class="b3-partner__warn-icon color-filter--alert" iconFolder="Platform/Information"
              icon="Attention" width="21px" height="18.38px"></gor-icon>
            <span>{{('B3_PARTNER.CONNECTION_WARN.HEADER_TITLE' | translate)}}</span>
          </div>
          <div class="b3-partner__warn-message" [innerHTML]="'B3_PARTNER.CONNECTION_WARN.MESSAGE_A' | translate"></div>
          <div class="b3-partner__warn-message" [innerHTML]="'B3_PARTNER.CONNECTION_WARN.MESSAGE_B' | translate"></div>
        </div>
      </div>
    </div>
    <div *ngSwitchDefault [@stepChangeAnimation] class="provider-details">
      <!-- TODO get partnerLogoName from account-data file -->
      <div class='form-container'>
        <ng-container *ngIf="data.title!='CEI'">
          <div *ngIf="data.description" class='description' [innerHtml]="data.description|keepHtml"></div>
          <p *ngIf="data.urlGuia&&![666, 608].includes(data.userAccountData?.providerId)" class="step-by-step">
            <a class="asset-color" [href]="data.urlGuia" target="_blank" translate>Click here</a>
            {{'and see the step-by-step guide to connect'|translate}}
          </p>
        </ng-container>
        <div *ngIf="!data.iframeUrl else iframe_form" class="form">
          <ng-container *ngIf="data.title=='CEI'">
            <p class="provider-details__form-title">Informe seu CPF/CNPJ e aceite os termos para conectar as contas.</p>
          </ng-container>
          <ng-container *ngIf="data.title=='B3'">
            <p class="provider-details__form-title-b3"> {{'B3_PARTNER.CONNECTION.FORM_TITLE' | translate}}</p>
          </ng-container>
          <form [formGroup]="form" autocomplete="off">
            <ng-container *ngFor="let signUpField of data.signUpFields">
              <!-- TODO login field should be generic as others text fields -->
              <mat-form-field id="loginField" *ngIf="signUpField=='login'">
                <mat-error *ngIf="!!loginFailure">{{loginFailure | translate}}</mat-error>
                <input *ngIf="data.title != 'B3'" matInput type='text'
                  [placeholder]="data.title === 'CEI' ? 'CPF/CNPJ' : 'Login'" formControlName="login" required>
                <input *ngIf="data.title === 'B3'" matInput type='text' [placeholder]="'CPF/CNPJ'" formControlName="login"
                  [value]="debouncedLoginValue$ | async | cpfCnpj" maxlength="18"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="inputB3" required>
              </mat-form-field>
              <ng-container *ngIf="signUpField=='password'">
                <mat-form-field>
                  <input matInput type='password' placeholder="Password" [formControlName]="signUpField" required>
                </mat-form-field>
              </ng-container>

              <ng-container *ngIf="signUpField!='login' && signUpField!='password'">
                <mat-form-field>
                  <input matInput type='text' [placeholder]="data.fields[signUpField]" [formControlName]="signUpField"
                    required>
                </mat-form-field>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!terms;else termsTemplate">
              <div class="terms-input">
                <gor-icon iconFolder="Selectors/Checkbox" [icon]="form.value.check?'Selected-Green':'Unselected'"
                  height="16px" width="16px"></gor-icon>
                <mat-checkbox ngDefaultControl formControlName="check">
                  {{'connectAccount' | translate | ucfirst}}
                </mat-checkbox>
              </div>
            </ng-container>
            <ng-template #termsTemplate>
              <span class="terms-area" [innerHtml]="data.terms|keepHtml">
              </span>
              <div class="terms-input">
                <mat-checkbox ngDefaultControl formControlName="check">
                  <gor-icon iconFolder="Selectors/Checkbox" [icon]="form.value.check?'Selected-Green':'Unselected'"
                    height="16px" width="16px"></gor-icon>
                  {{'I have read and accept the terms' | translate}}
                </mat-checkbox>
              </div>
            </ng-template>
            <p *ngIf="!['0', 666, '608'].includes(data.userAccountData?.providerId)" class="what-expect-connection"
              (click)="openModalConnectionExpectation(false, false)">
              <gor-icon class="what-expect-connection__icon" [iconFolder]="'Platform'" [icon]="'Information'"
                width='18px' height='18px'></gor-icon>
              <span>{{"What to expect from this connection" | translate | ucfirst}}</span>
            </p>

            <ng-container *ngIf="!animate; else animateTemplate">
              <div class='btn-group' *ngIf="![666].includes(data.userAccountData?.providerId)">
                <gor-button btnType='green' (click)="preSave(true, true)" [disabled]="!form.value.check||form.invalid">
                  {{"connect account" |
                  translate | ucfirst}}</gor-button>
              </div>

              <div *ngIf="errorMessage" class="error-wrapper">
                <p class="text-error" translate>{{errorMessage}}</p>
              </div>
            </ng-container>
            <ng-template #animateTemplate>
              <div class='btn-group'>
                <ui-progress-bar [animate]="animate"></ui-progress-bar>
              </div>
            </ng-template>
          </form>
        </div>
        <ng-template #iframe_form class="form">
          <iframe class="iframe-form" [src]="data.iframeUrl" width="380" height="100%"></iframe>
        </ng-template>
      </div>

    </div>
  </ng-container>
  <div class='btn-group-b3' *ngIf="[666].includes(data.userAccountData?.providerId)">
    <div class="btn-group-b3__footer-bottom">
      <p *ngIf="step !== 3" class="btn-group-b3__footer-text">Dúvidas? <a
          href="https://guia.gorila.com.br/hc/pt-br/articles/4411570469403-Conectar-Contas-B3" target="_blank">Acesse
          nosso guia</a></p>
      <div class="btn-group-b3__buttons">
        <a *ngIf="step === 1" href="https://www.investidor.b3.com.br/" class="b3-partner__buttons-back" target="_blank">
          <gor-button btnType="gray" mat-button>
            {{ 'B3_PARTNER.HAVE_ACCOUNT.BUTTON_TEXT' | translate }}
          </gor-button>
        </a>
        <gor-button *ngIf="step !== 1" btnType="gray" mat-button (click)="back()" class="btn-group-b3__buttons-back">
          {{ 'Back' | translate }}
        </gor-button>
        <gor-button btnType='green' (click)="nextStep()" [disabled]="step === 4 && !form.value.check && form.invalid">{{
          step === 1 ? ('B3_PARTNER.HAVE_ACCOUNT.NEXT_BUTTON_TEXT' | translate) : step !== 4 ? ('Next' | translate) :
          ('connect account' | translate)}}</gor-button>
      </div>
    </div>

  </div>
  <footer class="provider-details__footer">
    <gor-icon height="18px" width="18px" iconFolder="Platform/Information/Security" icon="Locked"></gor-icon>
    <p class="provider-details__footer-disclaimer">
      {{ 'PARTNER.CONNECTION.MODAL_FOOTER.DISCLAIMER' | translate }}
      <span class="provider-details__footer-link" translate
        (click)="openSecurityModal()">PARTNER.CONNECTION.MODAL_FOOTER.LINK</span>.
    </p>
  </footer>
</ng-container>
