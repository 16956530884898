import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FieldDataObject, FormBaseComponent, FormCreatorService } from '@gorila-bot/forms';
import { AuthFormEvent, AuthFormEventData, AuthFormMessage } from '../../models/authnolock.model';

@Component({
  selector: 'login-form',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent extends FormBaseComponent implements OnDestroy, OnInit {
  public readonly eventType = AuthFormEvent;

  public _waitingAuthResponse = false;
  public _btnText = 'Sign In';
  @Input() set waitingAuthResponse(waiting: boolean) {
    this._waitingAuthResponse = waiting;
    this._btnText = waiting ? '' : 'Sign In';
  }
  @Input() public message: AuthFormMessage = { text: '', error: false };
  @Input() public hideSocial = false;

  @Output() public loginEvent = new EventEmitter<AuthFormEventData>();

  public form: FormGroup;

  public fields: FieldDataObject = {
    email: {
      value: '',
      required: true,
      disabled: false,
      type: 'email',
      validators: [Validators.email]
    },
    password: {
      value: '',
      required: true,
      disabled: false,
      type: 'password',
      validators: [Validators.required]
    }
  };

  constructor(protected formCreatorService: FormCreatorService) {
    super(formCreatorService);
  }

  public ngOnInit() {
    super.ngOnInit();
    if (localStorage.getItem('social_error')) {
     this.message = {
      text: localStorage.getItem('social_error'),
      error: true
    };
  }
  }

  public ngOnDestroy() {}

  public getFields(): FieldDataObject {
    return this.fields;
  }

  public getName(): string {
    return 'SignupComponent';
  }

  public onClick(type: AuthFormEvent) {
    const data = {
      email: this.form.get('email').value,
      password: this.form.get('password').value
    };
    this.loginEvent.emit({ type, data });
  }
}
