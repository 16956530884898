import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PortfolioEvent } from '@gorila/pages/tools/portfolio-events/src/models/portfolio-event.model';
import * as moment from 'moment';
import { AppConstants } from '@gorila/constants';

@Pipe({
  name: 'eventDescription',
  pure: true
})
export class EventDescriptionPipe implements PipeTransform {

  constructor(private translate: TranslateService) {  }

  public transform(event: PortfolioEvent) {
    if (!event) { return ''; }
    const securityDescription = event.securityDescription;
    if (securityDescription.indexOf('Debenture') !== -1
        || securityDescription.indexOf('Call de') !== -1
        || securityDescription.indexOf('Put de') !== -1) {
      return event.securityName;
    }
    const eventType = event.eventType;
    if (eventType === 'FORWARD_EXPIRATION') {
      return this.translate.instant('FORWARD_EXPIRATION_START') + event.receivedAsset;
    }
    if (event.assetClass === 'FIXED INCOME') {
      return this.transformFixedIncomeEvent(event);
    }
    return this.translate.instant(securityDescription);
  }

  public transformFixedIncomeEvent({ securityDescription }: PortfolioEvent) {
    const initials = securityDescription.substring(0, 3);
    switch (initials) {
      case 'NTN':
      case 'LTN':
      case 'LFT':
        let date = securityDescription.substring(securityDescription.length - 8, securityDescription.length + 1);
        securityDescription = securityDescription.replace(date, ' ');
        date = moment.utc(date, 'YYYYMMDD').format(AppConstants.Format.Date.Brazilian);
        return securityDescription + date;
      case 'CDB':
      case 'LCI':
      case 'LCA':
      case 'LC ':
      case 'LF ':
        const index = securityDescription.indexOf('(');
        return index >= 0 ? securityDescription.substring(0, index).trim() : securityDescription;
      default:
         return securityDescription;
    }
  }
}
