import { Platform } from '@angular/cdk/platform';
import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { selectFeatureByPath, UserDataKey } from '@gorila-bot/gorila-store';
import {
  AuthConnectRoutePathUrl,
  AuthNoLockRoutePath,
  AuthNoLockSignupRoutePath,
  MobileWarningRoutePath,
} from '@gorila/core/router';
import { TOKEN_KEY, URL_OAUTH_TOKEN_KEY } from '@gorila/pages/auth/models/storage-keys.model';
import { Store } from '@ngrx/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class MobileGuard implements CanActivate, OnDestroy {

  private isMobile: boolean;
  private clientType: string;

  constructor(
    protected router: Router,
    public platform: Platform,
    protected store: Store<any>,
    protected location: Location,
  ) {
    this.isMobile = (this.platform.IOS || this.platform.ANDROID);
  }
  public ngOnDestroy(): void {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.store.pipe(
      map(feature => selectFeatureByPath(feature, [UserDataKey, 'clientType'])),
      take(1),
      untilDestroyed(this)
    ).subscribe(clientType => {
      this.clientType = clientType;
    });

    if (this.isMobile && this.clientType !== 'B2B') {
      const oauthToken = localStorage.getItem(URL_OAUTH_TOKEN_KEY);
      const lastRoute = localStorage.getItem('lastroute') || '';
      const page = state.url.substr(1).split('#')[0];
      const pagesAllowed = [
        AuthNoLockSignupRoutePath,
        AuthNoLockRoutePath,
        // These routes bellow works with the back-end invite service
        // If you have to modify then, please update then too in the
        // "advisor-feat" and "advisor tools" repositories
        'invite',
        'invite-accept',
        'invite-reject',
      ];
      if (
        lastRoute.startsWith('/m/') ||
        pagesAllowed.includes(page) && (this.location.path() === AuthConnectRoutePathUrl || oauthToken) ||
        !localStorage.getItem(TOKEN_KEY)
      ) {
        return true;
      }

      return this.router.navigateByUrl(`${MobileWarningRoutePath}`);
    }

    return true;
  }
}
